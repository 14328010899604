import React from "react"
import PropTypes from "prop-types"

import BoutonFermer from "../boutons/BoutonFermer"
import SEO from "./SEO"

import "../../css/base.css"
import "../../css/theme.css"
import "../../css/layouts/galerie-image-interactive.css"
import { verifierSiPageAEteAppeleeEnInterne } from "../../js/client-es/utils"

export default function LayoutGalerieImageInteractive(props) {
  const pageAppeleeDepuisSourceInterne = verifierSiPageAEteAppeleeEnInterne(
    props.propsPage
  )

  const codePageCiblePourBoutonFermer = obtenirPageCiblePourBoutonFermer(
    pageAppeleeDepuisSourceInterne,
    props.codePageRetour,
    props.page.langue
  )

  return (
    <main id="main-conteneur" style={{ marginLeft: 0 + "px" }}>
      <div id="galerie-image-interactive-conteneur">
        <SEO page={props.page} />
        <div id="galerie-image-interactive-conteneur-header-bouton-fermer">
          <BoutonFermer
            classeCSS="galerie-image-interactive-bouton-fermer"
            codePageCible={codePageCiblePourBoutonFermer}
          />
        </div>
        <div id="galerie-image-interactive-conteneur-image-plein-ecran">
          {props.composantImageInteractive}
        </div>
      </div>
    </main>
  )
}

function obtenirPageCiblePourBoutonFermer(accesInterne, codePageRetour) {
  if (accesInterne) {
    /*pour faire un history back (voir le comportement du composent "BoutonFerer*/
    return ""
  } else {
    /*sinon si appel extérieur, afficher la liste des images de la galerie*/
    return codePageRetour
  }
}

LayoutGalerieImageInteractive.propTypes = {
  page: PropTypes.object,
  propsPage: PropTypes.object,
  codePageRetour: PropTypes.string,
  composantImageInteractive: PropTypes.object,
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
}
