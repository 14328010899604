import React, { Component } from "react"
import PropTypes from "prop-types"

import OpenSeaDragon from "openseadragon"

export default class OpenSeaDragonViewer extends Component {
  constructor(props) {
    super(props)
    this.image = props.image
  }

  render() {
    return <div id="openseadragon-viewer-conteneur"></div>
  }

  componentDidMount() {
    this.viewer = OpenSeaDragon({
      defaultZoomLevel: 1.2,
      minZoomLevel: 0.8,
      navigatorBorderColor: "#FFFFFF",
      maxZoomPixelRatio: 2,
      animationTime: 1.2,
      showNavigator: false,
      prefixUrl: "/ressources/deep-zoom/openseadragon-nav-images/",
      tileSources: {
        Image: this.image,
      },
      element: "openseadragon-viewer-conteneur",
    })
  }
}

OpenSeaDragonViewer.propTypes = {
  image: PropTypes.object,
  description_image: PropTypes.object,
}
