import React from "react"
import PropTypes from "prop-types"

import Lien from "../navigation/Lien"

export default function BoutonFermer({
  classeCSS,
  codePageCible = "",
  ancre = "",
}) {
  if (codePageCible === "") {
    return (
      <a
        aria-label="Fermer / Close"
        href="#"
        onClick={e => {
          e.preventDefault()
          history.back()
        }}
        className={classeCSS}
      >
        {svgFermer()}
      </a>
    )
  } else {
    return (
      <Lien codePageCible={codePageCible} ancre={ancre} classeCSS={classeCSS}>
        {svgFermer()}
      </Lien>
    )
  }
}

function svgFermer() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="43"
      viewBox="0 0 24 24"
      width="43"
    >
      <path d="M0 0h24v24H0z" fill="none" />
      <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" />
    </svg>
  )
}

BoutonFermer.propTypes = {
  classeCSS: PropTypes.string,
  fonctionRetour: PropTypes.func,
}
