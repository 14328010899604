import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import LayoutGalerieImageInteractivePleinEcran from "../../../composants/layout/LayoutGalerieImageInteractivePleinEcran"
import OpenSeaDragonViewer from "../../../composants/image/OpenSeaDragonViewer"

import { obtenirPage } from "../../../js/client-es/utils"
import fragmentsPages from "../../../js/client-es/fragments/fragments-pages"

export const requete = graphql`
  {
    page: allPagesCompilationYaml(
      filter: { code: { eq: "megalaxyZoomAnnotee" } }
    ) {
      ...FragmentPageYaml
    }
  }
`

export default function MegalaxyZoomAnnote(props) {
  const resultatsRequete = useStaticQuery(requete)
  const page = obtenirPage(resultatsRequete, "page")

  const image = {
    xmlns: "http://schemas.microsoft.com/deepzoom/2009",
    Format: "jpg",
    Overlap: "1",
    Size: { Height: "16831", Width: "13655" },
    TileSize: "256",
    Url: "/ressources/deep-zoom/megalaxyzoom/tuiles-annotees/",
  }

  const composantImageInteractive = <OpenSeaDragonViewer image={image} />

  return (
    <LayoutGalerieImageInteractivePleinEcran
      page={page}
      propsPage={props}
      composantImageInteractive={composantImageInteractive}
      codePageRetour="galerieZoomsEt360Degres"
    ></LayoutGalerieImageInteractivePleinEcran>
  )
}
